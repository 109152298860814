/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        var language = $("meta[property='og:locale']").attr('content');
        language = language.split("_");

        $('.date-picker, .pickup-date input, .return-date input').datepicker({minDate:  new Date()}, $.datepicker.regional[language[0]]);

        $('body').on('click', '.rotatable', function(e) {
          $(this).find('.fa').not('.fa-circle-o').toggleClass('fa-rotate-180');
        }).on('click', 'ul.dropdown-menu li', function(e) {
          $(this).parents('.dropdown').find('.dropdown-toggle .dd-content').html($(this).find('a').html());
          $(this).parents('.dropdown').find('.active').removeClass('active');
          $(this).addClass('active');
        });

        $(window).on('resize', resizeElementsWidth);
        resizeElementsWidth();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'step_1': {
      init: function() {
        $('body').on('change', '.date-picker', function() {

          var from = $.trim($('#date_from').val());
          var to = $.trim($('#date_to').val());

          var toDate, fromDate;


          if (from !== '') {
            from = from.split('.');
            fromDate = new Date(new Date(from[2], from[1]-1, from[0]));
             $("#date_to").datepicker("option", "minDate", fromDate);
          }

          if (to !== '') {
            to = to.split('.');
            toDate = new Date(new Date(to[2], to[1]-1, to[0]));
            $("#date_from").datepicker("option", "maxDate", toDate);
          }

          if (from !== '' && to !== '') {
            var millisBetween =  new Date(toDate.getTime() - fromDate.getTime());
            var days = millisBetween / (1000 * 60 * 60 * 24);
            days = days + 1;

            $('.rent-period').text('Antall dager: ' + days);
          }
        });
        // JavaScript to be fired on the about us page
      }
    },
    'step_3': {
      init: function() {
        $('body').on('change', '.count-control', function() {

          var select = $(this);
          var key = select.data('key');
          var parent = select.parents('li');
          var price = $.trim(parent.find('.price-input').val()) * $(this).val();
          var title = parent.find('.title').text();

          $('.summary-info').each(function(){
            $(this).find('.' + key).remove();

            if (select.val() !== "0") {
              $(this).find('tbody').append('<tr class="'+key+'"><td class="col-md-8">'+title+'</td><td class="col-md-4 price">'+$.number(price, 2, ',', ' ' )+'</td>');
              parent.addClass("selected");
            } else {
              parent.removeClass("selected");
            }

            var total = 0;
            $(this).find('tbody .price').each(function(){
              total += parseFloat($(this).text().replace(" ", '').replace(",", "."));
            });

            $(this).find('.total .price').text($.number(total, 2, ',', ' ' ));
            $(this).find('.discount .price').text($.number(total*0.1, 2, ',', ' ' ));
          });
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function resizeElementsWidth()
{
  jQuery('*').each(function() {
    if (jQuery(this).width() > window.innerWidth) {
      jQuery(this).css('width', window.innerWidth);
    }
  });
}
